import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {

};

const account = createSlice({
  name: 'account',
  initialState: INITIAL_STATE,
  reducers: {
    set: (state, action) => action.payload,
    reset: (state) => INITIAL_STATE,
  },
});

export const getAccount = (state: any) => state.account;

export default account;
