import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appSlice, { getAppCoordinates } from 'store/slices/app';
import * as WarehouseUI from './warehouse/WarehouseUI';
import LoadingAnimation from '../../common/lottie/Loading.json';
import LottieAnimation from '../../Lottie';
import QrReader from 'react-web-qr-reader';
import { scannerSoundGood } from 'components/audio/scanSounds';
import { scannerAPI } from 'api/scanner';
import { Button, message } from 'antd';
import { StyledDivider } from './modals/ModalStyles';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &&& {
    width: 100%;
    height: 40px;
    border-radius: 25px;
    margin: 10px 0px;
  }
`;

export default () => {
  const dispatch = useDispatch();
  const coordinates = useSelector(getAppCoordinates);
  const [cameraOn, setCameraOn] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);

  // scan label returned items
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        dispatch(appSlice.actions.setCoordinates({ latitude, longitude }));
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleError = (error: any) => {
    console.log("error: ", error);
  }

  const handleScan = async ({data}: any) => {
    try {
      setLoadingScan(true);
      scannerSoundGood.play();
      const {message: msg} = await scannerAPI('label/pickup', {
        method: "POST",
        body: {
          labelData: data,
          coordinates,
        }
      });
      message.success(msg);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoadingScan(false);
    }
  }

  return (
    <WarehouseUI.Container>
      <WarehouseUI.ScannerBox onClick={() => setCameraOn(!cameraOn)}>
        {
          loadingScan ? (
            <LottieAnimation lotti={LoadingAnimation} height={300} width={300} />
          ) : cameraOn ? (
            <QrReader
              facingMode='environment'
              delay={500}
              onError={handleError}
              onScan={handleScan}
              style={{
                borderRadius: '8px',
                height: '300px',
                width: '300px',
              }}
              resolution={800}
            />
          ) : (
            <WarehouseUI.CameraTextContainer>
              SCAN LABEL FOR PICKUP
            </WarehouseUI.CameraTextContainer>
          )
        }
        <StyledDivider />
        <StyledButton type='primary' danger={cameraOn} onClick={() => setCameraOn(!cameraOn)}>
          {
            cameraOn ? 'STOP' : 'START'
          }
        </StyledButton>
      </WarehouseUI.ScannerBox>
    </WarehouseUI.Container>
  );
};
