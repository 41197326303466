import React from 'react';
import styled from 'styled-components';
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAppSettingsOpen } from 'store/slices/app';
import { colors } from 'common/colors';
import { getAccount } from 'store/slices/account';
import appSlice from 'store/slices/app';
import { useHistory } from 'react-router';
import SwitchMode from './SwitchMode';
import { ScannerModeEnum } from 'components/utils';
import { auth } from 'firebase/config';

const MenuItem = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  margin: 20px 0px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const appSettingsOpen = useSelector(getAppSettingsOpen);

  async function onSignOut() {
    localStorage.clear();
    await auth.signOut();
    window.location.reload();
  }

  function onClose() {
    dispatch(appSlice.actions.setAppSettingsOpenState(false));
  }

  return (
    <Drawer
      closeIcon={<CloseOutlined style={{color: 'white'}} />}
      visible={appSettingsOpen}
      headerStyle={{
        backgroundColor: colors.mainBlack,
        color: 'white',
      }}
      bodyStyle={{
        backgroundColor: colors.mainGray,
        color: 'white',
      }}
      title={<div style={{color: 'white'}}>{`Hi ${account?.name}`}</div>}
      placement="right"
      onClose={onClose}
      width="100%"
    >
      <Container>
        <MenuItem onClick={() => history.push('/profile')}>View profile</MenuItem>
        {![`merchant`, `dlp`].includes(account.type) && account.hubId && account.type !== ScannerModeEnum.DROPOFF ? <SwitchMode mode={ScannerModeEnum.DROPOFF} /> : null}
        {![`merchant`, `dlp`].includes(account.type) && account.type !== ScannerModeEnum.DRIVER ? <SwitchMode mode={ScannerModeEnum.DRIVER} /> : null}
        {![`merchant`, `dlp`].includes(account.type) && account.type !== ScannerModeEnum.WAREHOUSE ? <SwitchMode mode={ScannerModeEnum.WAREHOUSE} /> : null}
        <MenuItem onClick={() => window.location.reload()}>
          Refresh App
        </MenuItem>
        <Footer>
          <Button type='primary' danger onClick={onSignOut} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <MenuItem>Log out</MenuItem>
          </Button>
        </Footer>
      </Container>
    </Drawer>
  )
};
