import { generateTeamColor } from 'common/colors';
import moment from 'moment';
import React from 'react';
import * as ModalStyles from './ModalStyles';

interface IProps {
  order: any;
  team: any;
  assignedWorker: any;
  onClose: () => void;
}

export default ({
  order,
  team,
  assignedWorker,
  onClose,
}: IProps) => {

  function renderPriorityBanner() {
    const dayAgo = moment().subtract(1, 'days');
    const printedAt = moment(order.printedAtUnix ?? order.printed_at);
    if (printedAt.unix() * 1000 < dayAgo.unix() * 1000) {
      const difference = moment().diff(printedAt, 'hours');
      return (
        <div
          style={{
            backgroundColor: '#ff4d4f',
            padding: '10px',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
            textAlign: 'center'
          }}
        >{`PRIORITY - Printed ${difference} hours ago`}</div>
      )
    }
  }

  return (
    <ModalStyles.Container>
      {renderPriorityBanner()}
      <ModalStyles.MainText>{order.orderNumber}</ModalStyles.MainText>
      <ModalStyles.StyledDivider />

      <div style={{backgroundColor: generateTeamColor(team?.backgroundColor), padding: '10px', borderRadius: '4px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ModalStyles.LabelText style={{color: 'black'}}>Zone</ModalStyles.LabelText>
        <ModalStyles.MainText style={{color: 'black'}}>{team?.name}</ModalStyles.MainText>
      </div>

      <ModalStyles.StyledDivider />

      {
        assignedWorker && (
          <>
            <ModalStyles.LabelText>Label</ModalStyles.LabelText>
            <ModalStyles.Row>
              <ModalStyles.MainText>{assignedWorker.name}</ModalStyles.MainText>
              {
                assignedWorker.sequence && (
                  <ModalStyles.CountContainer>
                    <ModalStyles.LabelText>Seq.</ModalStyles.LabelText>
                    <ModalStyles.MainText>{assignedWorker.sequence}</ModalStyles.MainText>
                  </ModalStyles.CountContainer>
                )
              }
            </ModalStyles.Row>
            <ModalStyles.StyledDivider />
          </>
        )
      }
      <div style={{height: '100px'}} />

      <ModalStyles.Footer>
        <ModalStyles.SecondaryButton onClick={onClose}>
          Close
        </ModalStyles.SecondaryButton>
      </ModalStyles.Footer>

    </ModalStyles.Container>
  )
};
