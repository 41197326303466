import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  appSettingsOpen: false,
  coordinates: null,
  warehouse: null,
  warehouses: [],
  teams: [],
  drivers: [],
};

const app = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    set: (state, action) => action.payload,
    reset: (state) => INITIAL_STATE,

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setAppSettingsOpenState: (state, action) => {
      state.appSettingsOpen = action.payload;
    },

    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },

    setWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },

    setWarehouses: (state, action) => {
      state.warehouses = action.payload;
    },

    setTeams: (state, action) => {
      state.teams = action.payload;
    },

    setDrivers: (state, action) => {
      state.drivers = action.payload;
    }
  },
});

export const getAppState = (state: any) => state.app;
export const getAppLoading = (state: any) => state.app.loading;
export const getAppSettingsOpen = (state: any) => state.app.appSettingsOpen;
export const getAppCoordinates = (state: any) => state.app.coordinates;
export const getAppWarehouse = (state: any) => state.app.warehouse;
export const getAppWarehouses = (state: any) => state.app.warehouses;
export const getAppTeams = (state: any) => state.app.teams;
export const getAppDrivers = (state: any) => state.app.drivers;

export default app;
