import React, {useEffect, useState} from 'react';
import { Howl, Howler } from 'howler';
import { Divider, Drawer, message } from 'antd';
import QrReader from 'react-web-qr-reader';
import { Coordinates } from 'common/routing';
import goodSound from '../../assets/goodsound.mp3';
import badSound from '../../assets/badsound.mp3';
import { getAccount } from 'store/slices/account';
import { useSelector } from 'react-redux';
import * as WarehouseUI from './warehouse/WarehouseUI';
import LoadingAnimation from '../../common/lottie/Loading.json';
import LottieAnimation from '../../Lottie';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from 'common/colors';
import styled from 'styled-components';
import { scannerAPI } from 'api/scanner';

const MenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 8px;
  color: white;

  &:hover {
    background: ${colors.secondaryGray};
    color: ${colors.mainBlack};
    padding-left: 10px;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  background: ${colors.mainBlack};
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
`;


Howler.autoUnlock = true;
Howler.html5PoolSize = 100;

const audio = new Howl({
  src: [goodSound],
  html5: true
});

const audioBad = new Howl({
  src: [badSound],
  html5: true
});

export default () => {
  const account = useSelector(getAccount);
  const [loading, setLoading] = useState<boolean>(false);
  const [scannerOn, setScannerOn] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [lastScan, setLastScan] = useState<string>('');
  const [locations, setLocations] = useState<any[]>([]);
  const [defaultLocation, setDefaultLocation] = useState<string>();
  const [showLocationDrawer, setShowLocationDrawer] = useState<boolean>(false);
  
  useEffect(() => {
    getUserLocation();
  }, [])

  useEffect(() => {
    getPickupLocations();
  }, []);

  useEffect(() => {
    if (account.defaultLocation) {
      setDefaultLocation(account.defaultLocation);
    }
  }, [account.defaultLocation]);

  async function getPickupLocations() {
    try {
      const l = await scannerAPI('dlp/locations');
      setLocations(l);
    }
    catch (err) {
      console.log(err);
    }
  }

  async function updateDefaultLocation(locationId: string){
    try {
      const dl = await scannerAPI('user/defaultLocation', {
        method: "PUT",
        body: {
          locationId
        }
      });
      setDefaultLocation(dl);
      message.success('Location updated successfully');
    }
    catch (err) {
      message.error('Error updating default location')
    }
  }

  function getUserLocation() {
    if('geolocation' in navigator) {
      console.log("GEOLOCATION ON")
      /* geolocation is available */
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setCoordinates(coords);
        });
      })
    }
  }

  async function handleScan(scan: any) {
    const scanId = scan.data;
    if (!scanId || scanId === lastScan) {
      return;
    }
    setLastScan(scanId);
    Howler.unload();
    try {
      setLoading(true);
      await scannerAPI('label', {
        method: "POST",
        body: {
          labelData: scanId,
          coordinates: coordinates
        }
      });
      audio.play();
      message.success('Scan successful!')
    } catch (err: any) {
      console.log(err);
      audioBad.play();
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleError = (error: any) => {
    console.log(error);
    message.error(error.message);
  };

  return (
    <>
      <WarehouseUI.Container>
        <WarehouseUI.ScannerBox onClick={() => setScannerOn(!scannerOn)}>
          {
            loading ? (
              <LottieAnimation lotti={LoadingAnimation} height={300} width={300} />
            ) : scannerOn ? (
              <QrReader
                facingMode='environment'
                delay={500}
                onError={handleError}
                onScan={handleScan}
                style={{
                  borderRadius: '8px',
                  height: '300px',
                  width: '300px',
                }}
                resolution={800}
              />
            ) : (
              <WarehouseUI.CameraTextContainer>
                {'DROPOFF SCAN'}
                <div style={{fontSize: '16px' }}>{'Start Camera'}</div>
              </WarehouseUI.CameraTextContainer>
            )
          }
        </WarehouseUI.ScannerBox>
      </WarehouseUI.Container>
      <Divider />
      <Footer onClick={() => setShowLocationDrawer(true)}>
        <WarehouseUI.MainText>Select Location</WarehouseUI.MainText>
      </Footer>
      <Drawer
        closeIcon={<CloseOutlined style={{color: 'white'}} />}
        visible={showLocationDrawer}
        headerStyle={{
          backgroundColor: colors.mainBlack,
          color: 'white',
        }}
        bodyStyle={{
          backgroundColor: colors.mainGray,
          color: 'white',
        }}
        title={<MenuItem>Select Location</MenuItem>}
        placement="bottom"
        onClose={() => setShowLocationDrawer(false)}
        width="100%"
      >
        {
          locations.map((location: any) => {
            return (
              <MenuItem onClick={() => updateDefaultLocation(location.id)}>{location.verified.formattedAddress}</MenuItem>
            )
          })
        }
      </Drawer>
    </>
  )
}

