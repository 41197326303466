import { Button, message } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import appSlice from 'store/slices/app';
import accountSlice from 'store/slices/account';
import { scannerAPI } from 'api/scanner';

const MenuItem = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

interface IProps {
  mode: string;
}

export default ({
  mode,
}: IProps) => {
  const dispatch = useDispatch();
  async function onSwitchMode() {
    try {
      dispatch(appSlice.actions.setLoading(true));
      const data = await scannerAPI('user/mode', {
        method: "PUT",
        body: {
          type: mode,
        }
      })
      dispatch(accountSlice.actions.set(data));
      dispatch(appSlice.actions.setAppSettingsOpenState(false));
    } catch (err: any) {
      message.error(err.message);
    }
  }

  return (
    <MenuItem onClick={onSwitchMode}>
      Use {capitalize(mode)} Mode
    </MenuItem>
  )
};
