import goodSound from '../../assets/goodsound.mp3';
import badSound from '../../assets/badsound.mp3';
import successSound from '../../assets/successSound.mp3';

import { Howl, Howler } from 'howler';
Howler.autoUnlock = true;
Howler.html5PoolSize = 100;

export const scannerSoundGood = new Howl({
  src: [goodSound],
  html5: true
});
export const scannerSoundBad = new Howl({
  src: [badSound],
  html5: true
});
export const scannerSoundSuccess = new Howl({
  src: [successSound],
  html5: true
});
