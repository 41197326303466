import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Input } from 'antd';
import { colors } from 'common/colors';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAppWarehouses } from 'store/slices/app';
import styled from 'styled-components';

interface IProps {
  showSelector: boolean;
  onClose: () => void;
  onSelect: (team: any) => void;
}

const MenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 8px;
  color: white;

  &:hover {
    background: ${colors.secondaryGray};
    color: ${colors.mainBlack};
    padding-left: 10px;
  }
`;

const Search = styled(Input)`
  &&& {
    border: none;
    color: white;
    background: ${colors.mainBlack};
    font-size: 16px;
    box-shadow: none;

    &:hover {
      border: none;
    }
  }
`;


export default ({
  showSelector = false,
  onClose,
  onSelect,
}: IProps) => {
  const warehouses = useSelector(getAppWarehouses);
  const [searchText, setSearchText] = useState('');

  const filteredWarehouses = useMemo(() => {
    const filtered = warehouses.filter((warehouse: any) => 
      warehouse.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return filtered;
  }, [warehouses, searchText]);

  return (
    <Drawer
      closeIcon={<CloseOutlined style={{color: 'white'}} />}
      visible={showSelector}
      headerStyle={{
        backgroundColor: colors.mainBlack,
        color: 'white',
      }}
      bodyStyle={{
        backgroundColor: colors.mainGray,
        color: 'white',
      }}
      title={
        <Search
          placeholder="Type warehouse name..."
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
      }
      placement="bottom"
      onClose={onClose}
      width="100%"
    >
      {
        filteredWarehouses.map((warehouse: any) => {
          return (
            <MenuItem onClick={() => onSelect(warehouse)}>{warehouse.name}</MenuItem>
          )
        })
      }
    </Drawer>
  )
};
