export const colors = {
  blue1: '#78C9F5',
  blue2: '#4090F8',
  blue3: '#011559',
  blue4: '#39437d',

  red1: '#FE4D35',
  red2: '#fa755a',

  mainBlack: '#202123',
  mainGray: '#353740',
  secondaryGray: '#A7A7A7',
  gray1: '#aab7c4',
  gray2: '#32325d',

  black: '#000000',

  highlight: '#fdfaee',
};


const GREEN = `#72e078`;
const BLUE = `#5a8ed1`;
const RED = `#db7b8c`;
const ORANGE = `#e3752b`;
const OTHER = `#b4b4b4`;
const PURPLE = `#a953d4`;

export const generateTeamColor = (backgroundColor?: string) => {
  if (backgroundColor) {
    if (backgroundColor === `green`) {
      return GREEN;
    } else if (backgroundColor === `blue`) {
      return BLUE;
    } else if (backgroundColor === `red`) {
      return RED;
    } else if (backgroundColor === `orange`) {
      return ORANGE;
    } else if (backgroundColor === `purple`) {
      return PURPLE;
    }
    else {
      return backgroundColor;
    }
  } else {
    return OTHER;
  }
}