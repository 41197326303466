import { Divider, message } from 'antd';
import { scannerAPI } from 'api/scanner';
import { WarehouseScanTypeEnum } from 'components/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from 'store/slices/account';
import appSlice, { getAppCoordinates, getAppWarehouse } from 'store/slices/app';
import ScanModeSelector from '../modals/ScanModeSelector';
import WarehouseSelector from '../modals/WarehouseSelector';

import * as WarehouseUI from './WarehouseUI';
import Receiver from './Receiver';
import BulkScan from './BulkScan';
import { formatOption } from '../utils';


export default () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const warehouse = useSelector(getAppWarehouse);
  const coordinates = useSelector(getAppCoordinates);
  const [scantype, setScantype] = useState<WarehouseScanTypeEnum>(account.scanType || WarehouseScanTypeEnum.RECEIVER);
  const [showScannerModes, setShowScannerModes] = useState(false);
  const [showWarehouses, setShowWarehouses] = useState(false);
  const [loadingWarehouse, setLoadingWarehouse] = useState(true);

  // scan label returned items
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        dispatch(appSlice.actions.setCoordinates({ latitude, longitude }));
        dispatch(appSlice.actions.setWarehouse(coordinates));
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
    syncTeams();
  }, []);

  useEffect(() => {
    if (coordinates?.latitude) {
      syncWarehouse();
    }
  }, [coordinates]);

  async function syncWarehouse() {
    try {
      setLoadingWarehouse(true);
      const w = await scannerAPI('warehouse', {
        method: 'POST',
        body: {
          coordinates,
        }
      });
      dispatch(appSlice.actions.setWarehouse(w));
    } catch (err) {}
    finally {
      setLoadingWarehouse(false);
    }
  }

  async function syncTeams() {
    try {
      const t = await scannerAPI('teams');
      dispatch(appSlice.actions.setTeams(t));
      const w = await scannerAPI('warehouses');
      dispatch(appSlice.actions.setWarehouses(w));
    } catch (err) {}
  }



  const onSelectScannerMode = async (mode: WarehouseScanTypeEnum) => {
    setScantype(mode);
    setShowScannerModes(false);
    try {
      await scannerAPI('user/mode', {
        method: "PUT",
        body: {
          type: account.type,
          scanType: mode,
        }
      });
      message.success(`Switched to ${formatOption(mode)} mode`);
    } catch (err: any) {
      message.error(err.message);
    }
  }

  const onSelectWarehouse = async (warehouse: any) => {
    try {
      dispatch(appSlice.actions.setWarehouse(warehouse));
      const {message: msg} = await scannerAPI('user/warehouse', {
        method: "PUT",
        body: {
          warehouseId: warehouse.id,
          warehouseName: warehouse.name,
        }
      });
      setShowWarehouses(false);
      message.success(msg);
    } catch (err: any) {
      message.error(err.message);
    }
  }

  const renderScanner = () => {
    if ([WarehouseScanTypeEnum.RECEIVER, WarehouseScanTypeEnum.SORTER, WarehouseScanTypeEnum.LABELER].includes(scantype)) {
      return (
        <Receiver
          scanType={scantype}
        />
      )
    } else if ([WarehouseScanTypeEnum.SORTERV2, WarehouseScanTypeEnum.CLOSE_SORT].includes(scantype)) {
      return (
        <BulkScan
          scanType={scantype}
        />
      )
    }
  }

  return (
    <>
      {renderScanner()}
      <Divider />
      <WarehouseUI.Footer>
        <WarehouseUI.FooterMode onClick={() => setShowScannerModes(true)}>
          <WarehouseUI.MainText>{formatOption(scantype)}</WarehouseUI.MainText>
        </WarehouseUI.FooterMode>
        <WarehouseUI.FooterWarehouse onClick={() => setShowWarehouses(true)}>
          {
            loadingWarehouse
            ? `Loading...`
            : warehouse?.name
            ? warehouse.name
            : `Select warehouse`
          }
        </WarehouseUI.FooterWarehouse>
      </WarehouseUI.Footer>
      <ScanModeSelector
        showSelector={showScannerModes}
        onClose={() => setShowScannerModes(false)}
        onSelect={onSelectScannerMode}
      />
      <WarehouseSelector
        showSelector={showWarehouses}
        onClose={() => setShowWarehouses(false)}
        onSelect={onSelectWarehouse}
      />
    </>
  )
};
