import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Input, message } from 'antd';
import { scannerAPI } from 'api/scanner';
import { colors } from 'common/colors';
import { WarehouseScanTypeEnum } from 'components/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from 'store/slices/account';
import { getAppDrivers } from 'store/slices/app';
import styled from 'styled-components';
import { formatOption } from '../utils';

interface IProps {
  showSelector: boolean;
  onClose: () => void;
  onSelect: (team: any) => void;
}

const MenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 8px;
  color: white;

  &:hover {
    background: ${colors.secondaryGray};
    color: ${colors.mainBlack};
    padding-left: 10px;
  }
`;

const Search = styled(Input)`
  &&& {
    border: none;
    color: white;
    background: ${colors.mainBlack};
    font-size: 16px;
    box-shadow: none;

    &:hover {
      border: none;
    }
  }
`;

export default ({
  showSelector = false,
  onClose,
  onSelect,
}: IProps) => {
  const drivers = useSelector(getAppDrivers);
  const [loading, setLoading] = useState(false);
  const [workers, setWorkers] = useState(drivers);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (drivers.length === 0) {
      syncWorkers();
    }
  }, [drivers]);


  async function syncWorkers() {
    try {
      setLoading(true);
      const response = await scannerAPI('workers', {
        method: "POST",
        body: {

        },
      });
      setWorkers(response);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  const filteredDrivers = useMemo(() => {
    const filtered = workers.filter((worker: any) => 
      worker.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return filtered;
  }, [workers, searchText]);

  return (
    <Drawer
      closeIcon={<CloseOutlined style={{color: 'white'}} />}
      visible={showSelector}
      headerStyle={{
        backgroundColor: colors.mainBlack,
        color: 'white',
      }}
      bodyStyle={{
        backgroundColor: colors.mainGray,
        color: 'white',
      }}
      title={<Search
        placeholder="Type driver name..."
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />}
      placement="bottom"
      onClose={onClose}
      width="100%"
    >
      {
        loading ? <MenuItem>Loading...</MenuItem> :
        filteredDrivers.map((option: any) => {
          return (
            <MenuItem onClick={() => onSelect(option)}>{option.name}</MenuItem>
          )
        })
      }
    </Drawer>
  )
};
