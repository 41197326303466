import React, {useState} from 'react';
import {Input, message} from 'antd';
import firebase from '../../firebase/config';
import styled from 'styled-components';
import { StyledButton } from 'common/styled/Components';
import { useHistory } from 'react-router';

const StyledInput = styled(({...props}) => <Input {...props} size="large" bordered={false} />)`
  &&& {
    font-size: 16px;
    width: 100%;
    border-bottom: gray solid 1px;
    color: white;

    > input {
      color: white;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
  gap: 25px;
`;

export default () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState<any>(null);
  const [inputCode, setInputCode] = useState('');


  async function onSendVerificationCode() {
    setLoading(true);
    try {
      if (phoneNumber.length < 10) {
        throw new Error(`invalid phone number`);
      }
      const verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {'size': 'invisible'});
      const auth = firebase.auth();
      const verificationCode = await auth.signInWithPhoneNumber('+1' + phoneNumber, verify);
      setVerificationCode(verificationCode);
      message.success('Verification code has been sent to your phone');
    }
    catch (err: any) {
      console.log(err)
      message.error(err.message)
    } finally {
      setLoading(false);
    }
  }

  const verifyCode = async () => {
    try {
      setLoading(true);
      await verificationCode.confirm(inputCode);
      history.push('/home');
      message.success('Signed in scanner');
    }
    catch (err: any) {
      if (err.code === 'auth/invalid-verification-code') {
        message.error(`Invalid SMS Verification code`);
      } else {
        message.error(err.message)
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <StyledInput
        prefix='+1'
        inputMode="numeric"
        onChange={(e: any) => setPhoneNumber(e.target.value)}
        placeholder='Phone number'
        disabled={loading}
      />
      {
        verificationCode && (
          <StyledInput
            inputMode="numeric"
            onChange={(e: any) => setInputCode(e.target.value)}
            placeholder='Verification Code'
            size="large"
            bordered={false}
            disabled={loading}
          />
        )
      }
      {
        verificationCode
        ?
        <StyledButton type='primary' onClick={verifyCode} loading={loading} disabled={inputCode.length === 0 || loading}>Log in</StyledButton>
        :
        <StyledButton type='primary' onClick={onSendVerificationCode} disabled={phoneNumber.length === 0 || loading} loading={loading}>Continue</StyledButton>
      }
      {
        verificationCode
        ?
        <div>
          <StyledButton type="text" danger onClick={onSendVerificationCode} disabled={loading} loading={loading}>
            Resend Verification Code
          </StyledButton>
        </div>
        :
        null
      }
      <div id='recaptcha-container' />
    </Container>
  )
}
