import {configureStore} from '@reduxjs/toolkit';
import accountSlice from './slices/account';
import appSlice from './slices/app';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  reducer: {
    account: accountSlice.reducer,
    app: appSlice.reducer,
  },
});

export default store;
