

export enum ScannerModeEnum {
  DRIVER = 'driver',
  MERCHANT = 'merchant',
  WAREHOUSE = 'warehouse',
  DROPOFF = 'dropoff',
  // PROFILE = 'profile',
  DLP = 'dlp',
};

export const AdminScanTypeEnum = Object.freeze({
  SINGLE_SCAN: 'single_scan',
  BULK_SCAN: 'bulk_scan',
  CLOSE_SCAN: 'close_scan',
});

// export const WarehouseScanTypeEnum = Object.freeze({
//   RECEIVER: 'receiver',
//   LABELER: 'labeler',
//   SORTER: 'sorter',
//   SORTERV2: 'sorterv2',
// });

export enum WarehouseScanTypeEnum {
  RECEIVER = 'receiver',
  LABELER = 'labeler',
  SORTER = 'sorter',
  SORTERV2 = 'sorterv2',
  CLOSE_SORT = 'close_sort',
  ADMIN = 'admin',
}

export enum PackageSizeEnum {
  NULL = "",
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
};

export const PackageMultiplierEnum = Object.freeze({
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
});

