import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space } from 'antd';
import { scannerAPI } from 'api/scanner';
import { colors } from 'common/colors';
import { scannerSoundBad, scannerSoundGood } from 'components/audio/scanSounds';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QrReader from 'react-web-qr-reader';
import { getAccount } from 'store/slices/account';
import { getAppCoordinates, getAppWarehouse } from 'store/slices/app';
import * as WarehouseUI from './WarehouseUI';
import LoadingAnimation from '../../../common/lottie/Loading.json';
import LottieAnimation from '../../../Lottie';
import { WarehouseScanTypeEnum } from 'components/utils';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import AssignedBin from '../modals/AssignedBin';
import { formatOption } from '../utils';
import CloseSortModal from '../modals/CloseSortModal';

interface IProps {
  scanType: WarehouseScanTypeEnum;
}

const StyledButton = styled(Button)`

   &&& {
    margin: 20px 0px;
    padding: 10px 20px;
    background: white;
    color: ${colors.mainBlack};
    border: white solid 2px;
    font-size: 22px;
    font-weight: 600;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    &:hover {
      border-color: ${colors.mainGray};
      background: ${colors.secondaryGray};
      color: ${colors.mainBlack};
    }
  }
`;

export default ({
  scanType
}: IProps) => {
  const coordinates = useSelector(getAppCoordinates);
  const warehouse = useSelector(getAppWarehouse);
  const account = useSelector(getAccount);

  const [cameraOn, setCameraOn] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [scannedOrder, setScannedOrder] = useState({});

  const [scannedOrders, setScannedOrders] = useState<string[]>([]);
  const [failedOrders, setFailedOrders] = useState<string[]>([]);

  const [closeSortModal, setCloseSortModal] = useState(false);
  
  const [rack, setRack] = useState<any>();

  useEffect(() => {
    if (scanType) {
      onReset();
    }
  }, [scanType]);

  const onReset = () => {
    setCloseSortModal(false);
    setCameraOn(false);
    setRack(null);
    setScannedOrders([]);
    setFailedOrders([]);
  }

  const handleRackScan = async ({data}: any) => {
    try {
      setLoadingScan(true);
      if (!data) {
        return;
      }

      scannerSoundGood.play();
      const response = await scannerAPI('scan/rack', {
        method: "POST",
        body: {
          rackId: data,
          labelData: data,
          coordinates: coordinates,
          warehouseId: warehouse?.id || account.warehouseId || '',
        }
      });

      setCameraOn(false);
      setRack(response);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoadingScan(false);
    }
  }

  const handlePackageScan = async ({data}: any) => {
    try {
      setLoadingScan(true);
      if (!data) {
        return;
      }

      const orderIdsInRack = [...rack.orderIds, ...rack.childOrderIds];
      if (orderIdsInRack.includes(data)) {
        setScannedOrders(Array.from(new Set([...scannedOrders, data])));
        scannerSoundGood.play();

        let orderKey = data;
        if (rack.childOrderIds.includes(data)) {
          const entries: any = Object.entries(rack.orderMap);
          for (const items of entries) {
            if (items[1].includes(data)) {
              orderKey = items[0];
              return;
            }
          }
        }
        
        const sequence = rack.orderIds.indexOf(orderKey) + 1;
        await scannerAPI('scan/sort', {
          method: "POST",
          body: {
            orderId: data,
            sequence,
            rackId: rack.id,
            driver: rack.id,
            box: [rack.name, sequence].join(' - '),
            scannedId: data,
            warehouseId: warehouse.id,
            coordinates: coordinates,
            scannerMode: 'sorter2-good',
          }
        });
      } else {
        setFailedOrders([...failedOrders, data]);
        scannerSoundBad.play();
        setCameraOn(false);
        const assignedOrder = await scannerAPI('scan/order/assigned', {
          method: "POST",
          body: {
            scanId: data,
            orderId: data,
            rackId: rack.id,
            scannedId: data,
            warehouseId: warehouse.id,
            coordinates: coordinates,
            scannerMode: 'sorter2-bad',
          }
        });
        setScannedOrder(assignedOrder);
      }

    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoadingScan(false);
    }
  }


  const renderModals = () => {
    switch (scanType) {
      case WarehouseScanTypeEnum.SORTERV2:
        if (scannedOrder) {
          return (
            <AssignedBin
              {...scannedOrder}
              onClose={() => {
                setScannedOrder({});
                setCloseSortModal(false);
              }}
            />
          )
        } else {
          return;
        }
      case WarehouseScanTypeEnum.CLOSE_SORT:
        if (!isEmpty(scannedOrder)) {
          return (
            <AssignedBin
              {...scannedOrder}
              onClose={() => {
                setScannedOrder({});
                setCloseSortModal(false);
              }}
            />
          )
        }
        return (
          <CloseSortModal
            scannedOrders={scannedOrders}
            rack={rack}
            onClose={() => {
              setScannedOrder({});
              setCloseSortModal(false);
            }}
            onSubmit={onReset}
          />
        )
    }
  }

  const renderModal = () => (
      <Modal
        closeIcon={<CloseOutlined style={{color: 'white'}} />}
        visible={!isEmpty(scannedOrder) || closeSortModal}
        footer={null}
        title={null}
        width="100%"
        closable={false}
        style={{
          top: 0,
          height: '100%',
        }}
        bodyStyle={{
          background: colors.mainBlack,
          color: 'white',
          height: '100vh',
        }}
      >
        {renderModals()}
      </Modal>
  )

  const toggleCamera = () => {
    if (!cameraOn) {
      if (!warehouse) {
        message.error(`Please select a warehouse to continue`);
        return;
      } else {
        setCameraOn(true);
      }
    } else {
      setCameraOn(false);
    }
  }

  const handleError = (error: any) => {
    console.log("error: ", error);
  }

  const toggleComplete = () => {
    setCloseSortModal(true);
    setCameraOn(false);
  }

  const renderCameraText = () => {
    if (rack) {
      return 'SCAN PACKAGE QR';
    }
    return 'SCAN RACK QR';
  }

  const rackQuantity = rack ? rack.orderIds.length + rack.childOrderIds?.length : 0;

  const renderCamera = () => {
    return (
      <WarehouseUI.ScannerBox onClick={toggleCamera}>
        {
          loadingScan ? (
            <LottieAnimation lotti={LoadingAnimation} height={300} width={300} />
          ) : cameraOn ? (
            <QrReader
              facingMode='environment'
              delay={500}
              onError={handleError}
              onScan={rack ? handlePackageScan : handleRackScan}
              style={{
                borderRadius: '8px',
                height: '300px',
                width: '300px',
              }}
              resolution={800}
            />
          ) : (
            <WarehouseUI.CameraTextContainer>{renderCameraText()}</WarehouseUI.CameraTextContainer>
          )
        }
      </WarehouseUI.ScannerBox>
    )
  }

  return (
    <>
      <WarehouseUI.Container>
        {
          rack && (
            <WarehouseUI.DisplayBox>
              <WarehouseUI.DisplayLabel>
                {rack.name}
              </WarehouseUI.DisplayLabel>
              <Space>
                <WarehouseUI.StyledButton onClick={() => onReset()}>
                  Reset Rack
                </WarehouseUI.StyledButton>
                {scanType === WarehouseScanTypeEnum.CLOSE_SORT && scannedOrders.length > 0 && (
                  <StyledButton onClick={toggleComplete}>
                    COMPLETE
                  </StyledButton>
                )}
              </Space>
              
            </WarehouseUI.DisplayBox>
          )
        }
        {renderCamera()}
        <WarehouseUI.ScannerTypes>
          <div>
            <WarehouseUI.LabelText>Scan Type</WarehouseUI.LabelText>
            <WarehouseUI.MainText>{formatOption(scanType)} Mode</WarehouseUI.MainText>
          </div>
          {
            rack && (
              <WarehouseUI.CountContainer>
                <WarehouseUI.LabelText>Scanned</WarehouseUI.LabelText>
                <WarehouseUI.MainText>{`${scannedOrders.length} / ${rackQuantity}`}</WarehouseUI.MainText>
              </WarehouseUI.CountContainer>
            )
          }
        </WarehouseUI.ScannerTypes>
      </WarehouseUI.Container>
      {renderModal()}
    </>
  )
}
