import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { colors } from 'common/colors';
import { WarehouseScanTypeEnum } from 'components/utils';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from 'store/slices/account';
import styled from 'styled-components';
import { formatOption } from '../utils';

interface IProps {
  showSelector: boolean;
  onClose: () => void;
  onSelect: (team: any) => void;
}

const MenuItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 8px;
  color: white;

  &:hover {
    background: ${colors.secondaryGray};
    color: ${colors.mainBlack};
    padding-left: 10px;
  }
`;


export default ({
  showSelector = false,
  onClose,
  onSelect,
}: IProps) => {
  const account = useSelector(getAccount);
  const displayedOptions = [
    WarehouseScanTypeEnum.RECEIVER,
    WarehouseScanTypeEnum.LABELER,
    WarehouseScanTypeEnum.SORTER,
    WarehouseScanTypeEnum.SORTERV2,
  ];

  if (account.adminToggle) {
    displayedOptions.push(WarehouseScanTypeEnum.CLOSE_SORT);
  }


  return (
    <Drawer
      closeIcon={<CloseOutlined style={{color: 'white'}} />}
      visible={showSelector}
      headerStyle={{
        backgroundColor: colors.mainBlack,
        color: 'white',
      }}
      bodyStyle={{
        backgroundColor: colors.mainGray,
        color: 'white',
      }}
      title={<MenuItem>Select Mode</MenuItem>}
      placement="bottom"
      onClose={onClose}
      width="100%"
    >
      {
        displayedOptions.map((option: any) => {
          return (
            <MenuItem onClick={() => onSelect(option)}>{formatOption(option)}</MenuItem>
          )
        })
      }
    </Drawer>
  )
};
