import { Button, message, Space } from 'antd';
import { scannerAPI } from 'api/scanner';
import { PackageSizeEnum } from 'components/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from 'store/slices/account';
import { getAppCoordinates, getAppWarehouse } from 'store/slices/app';
import TeamSelector from './TeamSelector';
import * as ModalStyles from './ModalStyles';
import { DownOutlined } from '@ant-design/icons';
import { generateTeamColor } from 'common/colors';
import { calculateWeightInLbs } from '../utils';

interface IProps {
  order: any;
  team: any;
  teams: any;
  assignedWorker: any;
  onClose: () => void;
  onSelectTeam: (t: any) => void;
}

export default ({
  order,
  team,
  teams,
  assignedWorker,
  onClose,
  onSelectTeam,
}: IProps) => {
  const account = useSelector(getAccount);
  const coordinates = useSelector(getAppCoordinates);
  const warehouse = useSelector(getAppWarehouse);

  const defaultWeightInLbs = order?.weight?.value ? calculateWeightInLbs(order.weight) : 0;

  const [saving, setSaving] = useState(false);
  const [weightInLbs, setWeightInLbs] = useState<number>(defaultWeightInLbs);
  const [packageSize, setPackageSize] = useState<PackageSizeEnum>(order.packageSize);

  useEffect(() => {
    if (order) {
      const defaultWeightInLbs = order?.weight?.value ? calculateWeightInLbs(order.weight) : 0;
      setWeightInLbs(defaultWeightInLbs);
      setPackageSize(order.packageSize);
    }
  }, [order]);

  const [showTeamSelector, setShowTeamSelector] = useState(false);

  if (!order) {
    onClose();
  }

  function renderPriorityBanner() {
    const dayAgo = moment().subtract(1, 'days');
    const printedAt = moment(order.printedAtUnix ?? order.printed_at);
    if (printedAt.unix() * 1000 < dayAgo.unix() * 1000) {
      const difference = moment().diff(printedAt, 'hours');
      return (
        <div
          style={{
            backgroundColor: '#ff4d4f',
            padding: '10px',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
            textAlign: 'center',
            marginBottom: '12px',
          }}
        >{`PRIORITY - Printed ${difference} hours ago`}</div>
      )
    }
  }

  const address = order.isRtm ? order?.origin?.verified?.formattedAddress : (
    order?.destination?.verified?.formattedAddress || `${order?.destination?.address1} ${order?.destination?.address2} ${order?.destination?.city}`
  );

  async function handleWeightChange() {
    try {
      setSaving(true);
      const {message: msg} = await scannerAPI('weight', {
        method: 'POST',
        body: {
          orderId: order.id,
          weightInLbs,
        }
      });

      message.success(msg);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setSaving(false);
    }
  }

  async function onSelectNewTeam(newTeam: any) {
    try {
      setShowTeamSelector(false);
      setSaving(true);
      const {message: msg} = await scannerAPI('order/team', {
        method: "POST",
        body: {
          orderId: order.id,
          teamId: newTeam.id,
          teamName: newTeam.name,
          coordinates,
          warehouseId: warehouse.id,
        }
      });

      message.success(msg);
      onSelectTeam(newTeam);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setSaving(false);
    }
  }

  const onChangeTeam = () => {
    if (account.adminToggle) {
      setShowTeamSelector(true);
    }
  }

  return (
    <ModalStyles.Container>
      {renderPriorityBanner()}
      <br />
      <ModalStyles.MainText>{order.orderNumber}</ModalStyles.MainText>
      <ModalStyles.StyledDivider />
      {order.serviceArea && (
        <div>
          <ModalStyles.LabelText style={{color: 'white'}}>Service Area</ModalStyles.LabelText>
          <ModalStyles.MainText style={{color: 'white'}}>{order.serviceArea.name}</ModalStyles.MainText>
          <ModalStyles.MainText style={{color: 'white'}}>{order.serviceArea.carrier}</ModalStyles.MainText>
          <ModalStyles.MainText style={{color: 'white'}}>{order.serviceArea.description}</ModalStyles.MainText>
          <ModalStyles.StyledDivider />
        </div>
      )}

      <div onClick={onChangeTeam} style={{backgroundColor: generateTeamColor(team?.backgroundColor), padding: '10px', borderRadius: '4px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ModalStyles.LabelText style={{color: 'black'}}>Zone</ModalStyles.LabelText>
        <ModalStyles.MainText style={{color: 'black'}}>{team?.name}</ModalStyles.MainText>
        {
          account.adminToggle && (
            <DownOutlined style={{color: 'black', fontWeight: 800}} />
          )
        }
      </div>
      <ModalStyles.StyledDivider />

      {
        assignedWorker && (
          <>
            <ModalStyles.LabelText>Assigned to</ModalStyles.LabelText>
            <ModalStyles.Row>
              <ModalStyles.MainText>{assignedWorker.name}</ModalStyles.MainText>
              {
                assignedWorker.sequence && (
                  <ModalStyles.CountContainer>
                    <ModalStyles.LabelText>Seq.</ModalStyles.LabelText>
                    <ModalStyles.MainText>{assignedWorker.sequence}</ModalStyles.MainText>
                  </ModalStyles.CountContainer>
                )
              }
            </ModalStyles.Row>
            <ModalStyles.StyledDivider />
          </>
        )
      }

      <>
        <ModalStyles.LabelText>Customer</ModalStyles.LabelText>
        <ModalStyles.MainText>{order?.recipient?.fullName}</ModalStyles.MainText>
      </>
      <>
        <ModalStyles.LabelText>Address</ModalStyles.LabelText>
        <ModalStyles.MainText>{address}</ModalStyles.MainText>
      </>

      <ModalStyles.StyledDivider />

      <>
        <ModalStyles.LabelText>Shipment Details</ModalStyles.LabelText>
        {weightInLbs === 0 && (
          <div
            style={{
              backgroundColor: '#fff',
              padding: '10px',
              color: 'red',
              fontWeight: 'bold',
              fontSize: '16px',
              textAlign: 'center',
              marginBottom: '12px',
            }}
          >{`PLEASE WEIGH THIS PACKAGE! CANNOT BE 0`}</div>
        )}
      </>


      <Space style={{marginTop: '20px'}}>
        <strong>Weight (in LBS): </strong>
        <ModalStyles.StyledInput
          value={weightInLbs}
          onChange={e => setWeightInLbs(parseInt(e.target.value, 10))}
          type="number"
          style={{width: '100px'}}
          suffix="lbs"
          min={20}
          max={150}
        />
        {
          weightInLbs > 0 && (
            <Button type="primary" onClick={handleWeightChange} loading={saving} disabled={saving}>Save</Button>
          )
        }
      </Space>
 

      <div style={{height: '100px'}} />

      <ModalStyles.Footer>
        <ModalStyles.SecondaryButton onClick={onClose}>
          Close
        </ModalStyles.SecondaryButton>
      </ModalStyles.Footer>

      {
        account.adminToggle && (
          <TeamSelector
            teams={teams}
            showSelector={showTeamSelector}
            onClose={() => setShowTeamSelector(false)}
            onSelect={onSelectNewTeam}
          />
        )
      }
    </ModalStyles.Container>
  );
};
