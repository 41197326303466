import { Weight, WeightUnitEnum } from "@shippie/shared";
import { capitalize } from "lodash";

export const formatOption = (option: string) => {
  return capitalize(option.replace('_', ' '));
}


const gramsToPounds = (weightInGrams: number): number => {
  const poundsPerGram = 0.00220462;
  return weightInGrams * poundsPerGram;
}

const ouncesToPounds = (weightInOunces: number): number => {
  const ouncesPerPound = 16;
  return weightInOunces / ouncesPerPound;
}

export const calculateWeightInLbs = (
  weight: Weight
): number => {
  let weightInLbs = 0;

  if (weight.units === WeightUnitEnum.POUNDS) {
    weightInLbs = weight.value;
  }

  if (weight.units === WeightUnitEnum.GRAMS) {
    weightInLbs = gramsToPounds(weight.value);
  }

  if (weight.units === WeightUnitEnum.OUNCES) {
    weightInLbs = ouncesToPounds(weight.value);
  }

  
  return weightInLbs;
}
