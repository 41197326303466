import { auth } from 'firebase/config';
import React from 'react';

import {Route, Redirect, RouteProps} from 'react-router';

const PrivateRoute: React.FC<RouteProps> = ({component: Component, ...rest}) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return <Redirect to='/login' />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // @ts-ignore
        return currentUser ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default PrivateRoute;
