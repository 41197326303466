import { MainContainer } from 'common/styled/Components';
import AppSettings from 'components/navigation/AppSettings';
import Header from 'components/navigation/Header';
import React from 'react';

interface IProps {
  children: React.ReactNode;
}

export default ({children}: IProps) => {
  const renderModals = () => {
    return (
      <>
        <AppSettings />
      </>
    )
  }

  return (
    <MainContainer>
      <Header />
      {children}
      {renderModals()}
    </MainContainer>
  );
};
