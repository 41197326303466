import React, { useState } from 'react';
import QrReader from 'react-web-qr-reader';
import * as ModalStyles from './ModalStyles';
import cameraImg from '../../../assets/cameraIconWhite.png';
import { scannerSoundBad, scannerSoundSuccess } from 'components/audio/scanSounds';
import SuccessAnimation from '../../../common/lottie/Success.json';
import FailureAnimation from '../../../common/lottie/Failure.json';
import LottieAnimation from '../../../Lottie';
import { Howler } from 'howler';
import { scannerAPI } from 'api/scanner';
import { useSelector } from 'react-redux';
import { getAppCoordinates, getAppWarehouse } from 'store/slices/app';

interface IProps {
  order: any;
  team: any;
  assignedWorker: any;
  onClose: () => void;
}

enum SortStatusEnum {
  FAILED = 'failed',
  SUCCESS = 'success',
  PENDING = 'pending',
}

export default ({
  order,
  team,
  assignedWorker,
  onClose,
}: IProps) => {
  const warehouse = useSelector(getAppWarehouse);
  const coordinates = useSelector(getAppCoordinates);
  const [cameraOn, setCameraOn] = useState(false);
  const [sortStatus, setSortStatus] = useState(SortStatusEnum.PENDING);

  const handleScan = async ({data}: any) => {
    if (data === assignedWorker.id) {
      scannerSoundSuccess.play();
      setSortStatus(SortStatusEnum.SUCCESS);

      setTimeout(() => {
        Howler.unload();
      }, 1000);

      await scannerAPI('scan/sort', {
        method: "POST",
        body: {
          orderId: order.id,
          sequence: assignedWorker.sequence,
          rackId: assignedWorker.id,
          driver: assignedWorker.id,
          scannedId: data,
          teamId: team.id,
          teamName: team.name,
          warehouseId: warehouse.id,
          coordinates: coordinates,
          scannerMode: 'sorter2-good',
        }
      });
    } else {
      scannerSoundBad.play();
      setSortStatus(SortStatusEnum.FAILED);

      await scannerAPI('scan/sort', {
        method: "POST",
        body: {
          orderId: order.id,
          sequence: assignedWorker.sequence,
          rackId: assignedWorker.id,
          driver: assignedWorker.id,
          scannedId: data,
          teamId: team.id,
          teamName: team.name,
          warehouseId: warehouse.id,
          coordinates: coordinates,
          scannerMode: 'sorter2-good',
        }
      });
    }
  }

  const handleClose = () => {
    setCameraOn(false);
    setSortStatus(SortStatusEnum.PENDING);
    onClose();
  }

  const handleError = (error: any) => {
    console.log("error: ", error);
  }

  const renderSorterBody = () => {
    if (sortStatus === SortStatusEnum.PENDING) {
      return (
        <ModalStyles.Center>
        <ModalStyles.ScannerBox onClick={() => setCameraOn(!cameraOn)}>
          {
            cameraOn ? (
              <QrReader
                facingMode='environment'
                delay={500}
                onError={handleError}
                onScan={handleScan}
                style={{
                  borderRadius: '8px',
                  height: '300px',
                  width: '300px',
                }}
                resolution={800}
              />
            ) : (
              <>
                <img style={{marginTop: '30%', color: 'white', marginBottom: '10px'}} src={cameraImg} />
                <ModalStyles.MainText>2. Scan a Rack label</ModalStyles.MainText>
              </>
            )
          }
        </ModalStyles.ScannerBox>
      </ModalStyles.Center>
      )
    } else if (sortStatus === SortStatusEnum.SUCCESS) {
      return (
        <ModalStyles.Center>
          <LottieAnimation lotti={SuccessAnimation} height={300} width={300} />
          <ModalStyles.MainText>Success!</ModalStyles.MainText>
        </ModalStyles.Center>
      )
    } else {
      return (
        <ModalStyles.Center>
          <LottieAnimation lotti={FailureAnimation} height={300} width={300} />
          <ModalStyles.MainText>Incorrect Rack</ModalStyles.MainText>
          <ModalStyles.StyledDivider />

          <ModalStyles.MainButton onClick={() => setSortStatus(SortStatusEnum.PENDING)}>
            Scan again
          </ModalStyles.MainButton>
        </ModalStyles.Center>
      )
    }
  }

  return (
    <ModalStyles.Container>
      <ModalStyles.MainText>{order.orderNumber}</ModalStyles.MainText>
      <ModalStyles.StyledDivider />

      {
        assignedWorker && (
          <>
            <ModalStyles.LabelText>Label</ModalStyles.LabelText>
            <ModalStyles.Row>
              <ModalStyles.MainText>{assignedWorker.name}</ModalStyles.MainText>
              {
                assignedWorker.sequence && (
                  <ModalStyles.CountContainer>
                    <ModalStyles.LabelText>Seq.</ModalStyles.LabelText>
                    <ModalStyles.MainText>{assignedWorker.sequence}</ModalStyles.MainText>
                  </ModalStyles.CountContainer>
                )
              }
            </ModalStyles.Row>
            <ModalStyles.StyledDivider />
          </>
        )
      }
      {renderSorterBody()}
      
      <div style={{height: '100px'}} />

      <ModalStyles.Footer>
        <ModalStyles.SecondaryButton onClick={handleClose}>
          Close
        </ModalStyles.SecondaryButton>
      </ModalStyles.Footer>
    </ModalStyles.Container>
  )
}
