// local
// export const FUNCTIONS_URL = 'http://localhost:5000/shippie-prod/us-central1';
// export const SHIPPIE_URL = 'https://localhost:3000';

// dev
// export const FUNCTIONS_URL = "https://us-central1-shippie-dev.cloudfunctions.net";
// export const SHIPPIE_URL = 'https://shippie-dev.web.app';

// staging
// export const FUNCTIONS_URL = "https://us-central1-shippie-dev.cloudfunctions.net";
// export const SHIPPIE_URL = 'https://staging.shippie.ca';

// prod
export const FUNCTIONS_URL = 'https://us-central1-shippie-prod.cloudfunctions.net';
export const SHIPPIE_URL = 'https://app.shippie.ca';
