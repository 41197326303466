import React from 'react';
import { Button, Divider, Form, Input } from 'antd';
import {auth} from '../../firebase/config';
import styled from 'styled-components';
import { useHistory } from 'react-router';

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();

  async function onSubmit(formData: any) {
    const {email, password} = formData;
    const {user} = await auth.signInWithEmailAndPassword(email, password);
    history.push('/home');
  }

  const Container = styled.div`
    margin: 20px;
  `;

  const WelcomeSign = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  `;
  const Label = styled.div`
    color: white;
  `;

  const StyledButton = styled(({...props}) => <Button {...props} />)`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    font-weight: bold;
    border: none;
  `;

  const StyledInput = styled(({...props}) => <Input {...props} size="large" bordered={false} />)`
    font-size: 16px;
    width: 100%;
    border-bottom: gray solid 1px;
    color: white;
  `;


  return (
    <Container>
      <Form onFinish={onSubmit} layout='vertical' form={form}>
        <Form.Item
          label={<Label>Email</Label>}
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <StyledInput />
        </Form.Item>
        <Form.Item
          label={<Label>Password</Label>}
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <StyledInput type="password" />
        </Form.Item>
        <Divider />
        <Form.Item>
          <StyledButton type="primary" htmlType="submit">
            Submit
          </StyledButton>
        </Form.Item>
      </Form>
    </Container>
  )
}
