import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';

import firebase, { auth } from './firebase/config';
import Login from './components/auth/Login';
import App from './components/App';
import {message} from 'antd';
import LottieAnimation from './Lottie';
import LoadingAnimation from './common/lottie/Loading.json';
import accountSlice from './store/slices/account';
import appSlice from './store/slices/app';
import { scannerAPI } from 'api/scanner';
import { Router } from 'react-router';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import Profile from 'components/modes/Profile';
import PrivateRoute from 'routes/PrivateRoute';
import { AuthProvider } from 'components/auth/AuthContext';
import Layout from 'routes/Layout';
import { colors } from 'common/colors';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  async function loadUser() {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        try {
          dispatch(appSlice.actions.setLoading(true));
          setLoading(true);
          const data = await scannerAPI('user');
          dispatch(accountSlice.actions.set(data));
          history.push(`/home`);
        } catch (err: any) {
          console.log(err);
          message.error(err.message);
        } finally {
          dispatch(appSlice.actions.setLoading(false));
          setLoading(false);
        }
      } else {
        history.push(`/login`);
        auth.signOut();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    const check = ()=> {
      if (!document.hasFocus()) {
          window.location.reload();
      }
    }
    setInterval(check, 1000*60*15)
  }, []);

  if (loading) {
    return (
      <div style={{height: `100vh`, display: `flex`, justifyContent: `center`, alignItems: `center`, background: colors.mainGray}}>
        <LottieAnimation lotti={LoadingAnimation} height={300} width={300} />
      </div>
    )
  }
  return (
    <AuthProvider>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <Layout>
            <Switch>
              <PrivateRoute path="/home" component={App} />
              <PrivateRoute path="/profile" component={Profile} />
            </Switch>
          </Layout>
          <Route path='*' render={() => <Redirect to='/home' />} />
        </Switch>
      </Router>
    </AuthProvider>
  )
}
