import React from 'react';
import { ScannerModeEnum } from './utils';
import Driver from './modes/Driver';
import Dropoff from './modes/Dropoff';
import { useSelector } from 'react-redux';
import { getAccount } from '../store/slices/account';
import DLP from './modes/DLP';
import Warehouse from './modes/warehouse/Warehouse';

export default () => {
  const account = useSelector(getAccount);

  const renderModes = () => {
    switch (account.type) {
      case ScannerModeEnum.DRIVER:
        return <Driver />;
      case ScannerModeEnum.DROPOFF:
        return <Dropoff />;
      case ScannerModeEnum.WAREHOUSE:
        return <Warehouse />;
      case ScannerModeEnum.DLP:
        return <DLP />
      default:
        return;
    }
  };

  return (
    <>
      {renderModes()}
    </>
  );

}
