import React, {useEffect, useState} from 'react';
import Radium from 'radium';
import QrReader from 'react-web-qr-reader';
import {useSelector} from 'react-redux';
import {Button, message} from 'antd';
import cameraImg from '../../assets/cameraIcon.png';
import goodSound from '../../assets/goodsound.mp3';
import { getAccount } from '../../store/slices/account';
import { Howl, Howler } from 'howler';

Howler.autoUnlock = true;
Howler.html5PoolSize = 100;

function Dropoff() {
  const account = useSelector(getAccount);
  const audio = new Howl({
    src: [goodSound],
    html5: true
  });
  const [scannerOn, setScannerOn] = useState(false);
  const [lastScanned, setLastScanned] = useState();

  function requestCameraPermissions() {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({video: true});
    }
  }

  const handleScan = async (scan: any) => {
    const scanId = scan.data;
    if (scanId && lastScanned !== scanId) {
      setLastScanned(scanId);
      try {
        // audio.play();
        // scan_label({scanId, hubId: account.hubId});
        message.success('Package scanned');
      }
      catch (err: any) {
        message.error(err.message);
      }
    }
    else {
      message.warning('This package was just scanned')
    }
  };

  const handleError = (error: any) => {
    message.error(error.message);
  };

  function updateScanMode(on: boolean) {
    setScannerOn(on);
    requestCameraPermissions();
  }


  const renderScanner = () => {
    if (scannerOn) {
      return (
        <div style={comStyles().body}>
           <div style={comStyles().scanner} onClick={() => updateScanMode(false)}>
            <QrReader facingMode='environment' delay={500} style={comStyles().qrCam} onError={handleError} onScan={handleScan} />
          </div>
          <br />
          <Button style={comStyles().button} type='primary' danger onClick={() => setScannerOn(false)}>
            STOP
          </Button>
        </div>
      )
    }
    else {
      return (
        <div style={comStyles().body}>
          <div style={comStyles().scanner} onClick={() => updateScanMode(true)}>
            <img style={{marginTop: '30%'}} src={cameraImg} />
            <div>Start Camera Below</div>
          </div>
          <br />
          <Button style={comStyles().button} type='primary' onClick={() => setScannerOn(true)}>
            START
          </Button>
        </div>
      )
    }
  }

  function renderFooter() {
    return (
      <div style={comStyles().footer}>
        {
          // account.warehouseToggle && !scannerOn
          // ?
          // <SwitchModeButton mode={`warehouse`} />
          // :
          // null
        }
      </div>
    )
  }

  return (
    <div style={comStyles().body}>
      <div style={comStyles().row}> 
        <b style={comStyles().title}>Dropoff Scan</b>
      </div>
      <div style={comStyles().body}>
        <p>Scan your packages received from driver dropoffs</p>
        {renderScanner()}
      </div>
      {renderFooter()}
    </div>
  );
}

const RadiumHOC = Radium(Dropoff);
export default RadiumHOC;

// the JS function that returns Radium JS styling
const comStyles: () => {[key: string]: React.CSSProperties} = () => {
  return {
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scanner: {
      alignItems: 'center',
      textAlign: 'center',
      height: 300,
      width: 300,
      border: '3px',
      borderColor: 'black',
      borderStyle: 'solid',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: 5
    },
    title: {
      fontSize: 20
    },
    button: {
      color: 'white',
      padding: `10px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '40px',
      borderRadius: '25px',
      fontWeight: 'bold',
      border: 'none',
      margin: '10px 0px',
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      margin: '15px 0px',
    }
  };
};
