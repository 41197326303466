import React from 'react';
import { useSelector } from 'react-redux';
import { getAccount } from 'store/slices/account';
import QRCode from "react-qr-code";
import styled from 'styled-components';
import { Divider } from 'antd';

const Title = styled.div`
  font-size: 22px;
  color: white;
  font-weight: bold;
`;

const Details = styled.div`
  margin: 10px 0px;
`;

const Container = styled.div`
  margin: 20px;
`;

export default () => {
  const account = useSelector(getAccount);

  return (
    <Container>
      <Title>Profile</Title>
      <Details>
        <div>
          Name: {account.name}
        </div>
        <div>
          Phone Number: {account.phoneNumber}
        </div>
      </Details>
      <Divider />
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={account.worker_id || account.id}
        viewBox={`0 0 256 256`}
      />
    </Container>
  )
};
