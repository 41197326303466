import { CloseOutlined } from '@ant-design/icons';
import { message, Modal, Switch } from 'antd';
import { scannerAPI } from 'api/scanner';
import { colors } from 'common/colors';
import { scannerSoundGood } from 'components/audio/scanSounds';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QrReader from 'react-web-qr-reader';
import { getAccount } from 'store/slices/account';
import { getAppCoordinates, getAppTeams, getAppWarehouse } from 'store/slices/app';
import ReceivedModal from '../modals/ReceivedModal';
import * as WarehouseUI from './WarehouseUI';
import LoadingAnimation from '../../../common/lottie/Loading.json';
import LottieAnimation from '../../../Lottie';
import { WarehouseScanTypeEnum } from 'components/utils';
import { capitalize } from 'lodash';
import LabelerModal from '../modals/LabelerModal';
import SorterModal from '../modals/SorterModal';

interface IProps {
  scanType: WarehouseScanTypeEnum;
}

export default ({
  scanType
}: IProps) => {
  const coordinates = useSelector(getAppCoordinates);
  const warehouse = useSelector(getAppWarehouse);
  const teams = useSelector(getAppTeams);
  const account = useSelector(getAccount);

  const [cameraOn, setCameraOn] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [scannedOrder, setScannedOrder] = useState();
  const [regionTeam, setRegionTeam] = useState();
  const [showOrderDetails, setShowOrderDetails] = useState(true);
  const [assignedWorker, setAssignedWorker] = useState();

  const onClear = () => {
    setScannedOrder(undefined);
    setRegionTeam(undefined);
  }

  const handleScan = async ({data}: any) => {
    try {
      setLoadingScan(true);
      onClear();
      if (!data) {
        return;
      }

      if (showOrderDetails) {
        // if showOrderDetails is off, continue scanning and don't show the popup modal.
        setCameraOn(false);
      }

      scannerSoundGood.play();
      const {message: msg, order, team, worker} = await scannerAPI('label', {
        method: "POST",
        body: {
          labelData: data,
          coordinates: coordinates,
          warehouseId: warehouse?.id || account.warehouseId || '',
          includeTask: scanType === WarehouseScanTypeEnum.SORTER,
          includeWorker: [WarehouseScanTypeEnum.SORTER, WarehouseScanTypeEnum.LABELER].includes(scanType),
          scanType,
        }
      });
      message.success(msg);
      if (showOrderDetails) {
        setScannedOrder(order);
        setRegionTeam(team);
        setAssignedWorker(worker);
      }
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoadingScan(false);
    }
  }


  const renderModals = () => {
    if (scanType === WarehouseScanTypeEnum.RECEIVER && !!scannedOrder) {
      return (
        <ReceivedModal
          order={scannedOrder}
          team={regionTeam}
          teams={teams}
          assignedWorker={assignedWorker}
          onClose={onClose}
          onSelectTeam={(t: any) => setRegionTeam(t)}
        />
      )
    } else if (scanType === WarehouseScanTypeEnum.LABELER && !!scannedOrder) {
      return (
        <LabelerModal
          order={scannedOrder}
          team={regionTeam}
          assignedWorker={assignedWorker}
          onClose={onClose}
        />
      )
    } else if (scanType === WarehouseScanTypeEnum.SORTER && !!scannedOrder) {
      return (
        <SorterModal
          order={scannedOrder}
          team={regionTeam}
          assignedWorker={assignedWorker}
          onClose={onClose}
        />
      )
    }
  }

  const renderModal = () => (
      <Modal
        closeIcon={<CloseOutlined style={{color: 'white'}} />}
        visible={!!scannedOrder}
        footer={null}
        title={null}
        width="100%"
        closable={false}
        style={{
          top: 0,
          height: '100%',
        }}
        bodyStyle={{
          background: colors.mainBlack,
          color: 'white',
          height: '100vh',
        }}
      >
        {renderModals()}
      </Modal>
  )

  const toggleCamera = () => {
    if (!cameraOn) {
      if (!warehouse) {
        message.error(`Please select a warehouse to continue`);
        return;
      } else {
        setCameraOn(true);
      }
    } else {
      setCameraOn(false);
    }
  }

  const handleError = (error: any) => {
    console.log("error: ", error);
  }

  const onClose = () => {
    setScannedOrder(undefined);
    setCameraOn(true);
  }

  const renderCameraText = () => {
    switch (scanType) {
      case WarehouseScanTypeEnum.RECEIVER:
        return 'RECEIVE SCAN';
      case WarehouseScanTypeEnum.LABELER:
        return 'LABEL SCAN';
      case WarehouseScanTypeEnum.SORTER:
        return 'SORT SCAN';
    }
  }

  const renderCameraDescription = () => {
    switch (scanType) {
      case WarehouseScanTypeEnum.RECEIVER:
        return `SCAN PACKAGE LABEL`;
      case WarehouseScanTypeEnum.LABELER:
        return 'SCAN PACKAGE LABEL';
      case WarehouseScanTypeEnum.SORTER:
        return 'SCAN PACKAGE LABEL';
    }
  }

  const handleLoad = () => {
    console.log("loaded");
  }

  return (
    <>
      <WarehouseUI.Container>
        <WarehouseUI.ScannerBox onClick={toggleCamera}>
          {
            loadingScan ? (
              <LottieAnimation lotti={LoadingAnimation} height={300} width={300} />
            ) : cameraOn ? (
              <QrReader
                facingMode='environment'
                delay={500}
                onError={handleError}
                onScan={handleScan}
                onLoad={handleLoad}
                style={{
                  borderRadius: '8px',
                  height: '300px',
                  width: '300px',
                }}
                resolution={800}
              />
            ) : (
              <WarehouseUI.CameraTextContainer>
                {renderCameraText()}
                <div style={{fontSize: '16px' }}>{renderCameraDescription()}</div>
              </WarehouseUI.CameraTextContainer>
            )
          }
        </WarehouseUI.ScannerBox>
        <WarehouseUI.ScannerTypes>
          <div>
            <WarehouseUI.LabelText>Scan Type</WarehouseUI.LabelText>
            <WarehouseUI.MainText>{capitalize(scanType)} Mode</WarehouseUI.MainText>
          </div>
          {
            scanType === WarehouseScanTypeEnum.RECEIVER && (
              <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: '8px'}}>
                <WarehouseUI.LabelText>Show Details</WarehouseUI.LabelText>
                <Switch
                  onChange={() => setShowOrderDetails(!showOrderDetails)}
                  checked={showOrderDetails}
                />
              </div>
            )
          }
        </WarehouseUI.ScannerTypes>
      </WarehouseUI.Container>
      {renderModal()}
    </>
  )
}
