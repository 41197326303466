import { Button } from "antd";
import { colors } from "common/colors";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: bold;
  min-height: 40px;
  border: none;
  border-radius: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: ${colors.mainGray};
  height: 100vh;
  width: 100vw;
`;
