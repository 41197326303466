import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

// switch between these to access the correct environment
// const BUILD_LEVEL = "dev";
export const BUILD_LEVEL = 'prod';


//DEV ENV
var devConfig = {
  apiKey: 'AIzaSyDSF1uogD8-mKE3n_MPxizuDt0CYRcnbA0',
  authDomain: 'shippie-dev.firebaseapp.com',
  projectId: 'shippie-dev',
  storageBucket: 'shippie-dev.appspot.com',
  messagingSenderId: '95922501679',
  appId: '1:95922501679:web:a8bcca89782feb95b2eeda',
  measurementId: 'G-GX14MSYH81',
};

// PROD ENV
const prodConfig = {
  apiKey: 'AIzaSyAbjIfNfabZbjCOwbSCKYO8tYjCX1ZQ0K8',
  authDomain: 'shippie-prod.firebaseapp.com',
  databaseURL: 'https://shippie-prod-default-rtdb.firebaseio.com',
  projectId: 'shippie-prod',
  storageBucket: 'shippie-prod.appspot.com',
  messagingSenderId: '1049969398720',
  appId: '1:1049969398720:web:1324dc1eff893139742e25',
  measurementId: 'G-TQN81EJWJW',
};


const fire = firebase.initializeApp(BUILD_LEVEL === 'prod' ? prodConfig : devConfig);
firebase.firestore().settings({ignoreUndefinedProperties: true, merge: true});

export const auth = fire.auth();

// firebase.functions().useEmulator('localhost', 5000);

export default firebase;
