import { Divider, Radio, Button, Input } from "antd";
import { colors } from "common/colors";
import styled from "styled-components";

export const Container = styled.div`
  background: ${colors.mainBlack};
  height: calc(100% - 60px);
  color: white;
`;

export const LabelText = styled.div`
  color: ${colors.secondaryGray};
`;

export const MainText = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

export const StyledDivider = styled(Divider)`
  background: white;
`;

export const RadioGroup = styled(Radio.Group)`
  &&& {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`;

export const RadioButton = styled(Radio.Button)`
  &&& {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.mainGray};
    border: none;
    color: white;
    width: 140px;
    height: 80px;
    text-align: center;
    
    .ant-radio-button {
      border-radius: 8px;
    }

    .ant-radio-button-checked {
      background: ${colors.mainBlack};
      border: white solid 2px;
    }
  }
`;

export const DescriptionText = styled.div`
  font-size: 10px;
  line-height: 2;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: ${colors.mainBlack};
`;

export const SecondaryButton = styled(Button)`
  background: ${colors.secondaryGray};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: ${colors.mainBlack};
  padding: 0px 25px;
  font-weight: 700;
  font-size: 18px;
  height: 45px;
`;

export const PrimaryButton = styled(Button)`
  background: ${colors.blue2};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: ${colors.mainBlack};
  padding: 0px 25px;
  font-weight: 700;
  font-size: 18px;
  height: 45px;
`;

export const StyledInput = styled(Input)`
  &&& {
    background: ${colors.mainGray};
    color: white;

    .ant-input-affix-wrapper {
      background: ${colors.mainGray};
      border-radius: 8px;
    }

    .ant-input {
      background: ${colors.mainGray};
      font-weight: 800;
      font-size: 20px;
      height: 40px;
      color: white;
    }
  }
`;

export const CountContainer = styled.div`
  width: 100px;
  background: ${colors.mainGray};
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ScannerBox = styled.div`
  align-items: center;
  text-align: center;
  height: 300px;
  width: 300px;
  border: 1px solid white;
  border-radius: 8px;
  background: ${colors.mainGray};
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const MainButton = styled(Button)`
  background: ${colors.mainGray};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: ${colors.mainBlack};
  padding: 0px 25px;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  color: white;
`;
