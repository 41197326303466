import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { colors } from 'common/colors';
import styled from 'styled-components';
import shippieIcon from '../../assets/shippieIcon.png';
import { useDispatch } from 'react-redux';
import appSlice from 'store/slices/app';
import { useHistory } from 'react-router-dom';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px 15px;
  background: ${colors.mainBlack};
`;

const Logo = styled.img`
  height: 30px;
`;

const MenuIcon = styled(MenuOutlined)`
  color: white;
`;

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAppSettingsState = () => {
    dispatch(appSlice.actions.setAppSettingsOpenState(true));
  }

  return (
    <Header>
      <Logo src={shippieIcon} onClick={() => history.push('/home')} />
      <MenuIcon onClick={handleAppSettingsState} />
    </Header>
  );
};

