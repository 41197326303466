import React from 'react';
import * as ModalStyles from './ModalStyles';
import * as WarehouseUI from '../warehouse/WarehouseUI';

export default ({
  name,
  sequence,
  onClose,
}: any) => {

  return (
    <ModalStyles.Container>
      <WarehouseUI.DisplayBox>
        <ModalStyles.MainText>Please bring this package to</ModalStyles.MainText>
        <WarehouseUI.DisplayLabel>
          {`${name} - ${sequence}`}
        </WarehouseUI.DisplayLabel>
      </WarehouseUI.DisplayBox>

      <ModalStyles.Footer>
        <ModalStyles.SecondaryButton onClick={onClose}>
          Close
        </ModalStyles.SecondaryButton>
      </ModalStyles.Footer>

    </ModalStyles.Container>
  )
};
