import { Divider } from 'antd';
import { MainContainer } from 'common/styled/Components';
import React, { useState } from 'react';
import styled from 'styled-components';
import shippieLogo from '../../assets/shippieLogo.png';
import LoginPhone from './LoginPhone';
import LoginUsername from './LoginUsername';


const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
`;

const Logo = styled.img`
  height: 50px;
`;

const Title = styled.h1`
  color: white;
  font-weight: bold;
`;

const ActionText = styled.p`
  color: white;
  font-weight: bold;
`;

export enum LoginType {
  Phone = 'phone',
  Username = 'username',
}

export default () => {
  const [loginType, setLoginType] = useState<string>(LoginType.Phone);

  const renderLogin = () => {
    if (loginType === LoginType.Username) {
      return (
        <div style={{textAlign: 'center'}}>
          <LoginUsername />
          <ActionText onClick={() => setLoginType(LoginType.Phone)}>login with phone</ActionText>
        </div>
      )
    }
    else {
      return (
        <div style={{textAlign: 'center'}}>
          <LoginPhone />
          <ActionText onClick={() => setLoginType(LoginType.Username)}>login with username</ActionText>
        </div>
      ) 
    }
  }
  return (
    <MainContainer>
      <Header>
        <Logo src={shippieLogo} />
      </Header>
      <Header>
        <Title>Login</Title>
      </Header>
      <Divider />
      {renderLogin()}
    </MainContainer>
  )
};
