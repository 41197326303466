import axios from 'axios';
import { auth } from 'firebase/config';
import { FUNCTIONS_URL } from './urls';

interface Options {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
}

function handleError(err: any) {
  throw err?.response?.data || err;
}

export async function scannerAPI(endpointURL: string, options?: Options) {
  try {
    const {method, body} = {method: 'GET', body: null, ...options};

    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
	
    const res = await axios(`${FUNCTIONS_URL}/scanner/${endpointURL}`, {
      method,
      ...(body && {data: body}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
		console.error(err)
    handleError(err);
  }
}
