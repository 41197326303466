import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as ModalStyles from './ModalStyles';
import * as WarehouseUI from '../warehouse/WarehouseUI';
import { message } from 'antd';
import { scannerAPI } from 'api/scanner';
import DriverSelector from './DriverSelector';

interface IProps {
  scannedOrders: string[];
  rack: any;
  onClose: () => void;
  onSubmit: () => void;
}

export default ({
  scannedOrders,
  rack,
  onClose,
  onSubmit,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const [showDriverSelector, setShowDriverSelector] = useState(false);

  const orderIds = useMemo(() => {
    return rack.orderIds;
  }, [rack]);

  const childOrderIds = useMemo(() => {
    return rack.childOrderIds;
  }, [rack]);

  const missingOrderIds = useMemo(() => {
    return [...orderIds, ...childOrderIds].filter((id: string) => !scannedOrders.includes(id));
  }, [orderIds, scannedOrders, childOrderIds]);

  const totalOrderIds = orderIds.length + childOrderIds.length;

  const getSequence = (id: string) => {
    let orderKey = id;
    if (rack.childOrderIds.includes(id)) {
      const entries: any = Object.entries(rack.orderMap);
      for (const items of entries) {
        if (items[1].includes(id)) {
          orderKey = items[0];
          return;
        }
      }
    }
    
    const sequence = rack.orderIds.indexOf(orderKey) + 1;
    return sequence;
  }


  const handleDriverSelect = (driver: any) => {
    setSelectedDriver(driver);
    handleCloseDriverSelector();
  }

  const handleCloseDriverSelector = () => {
    setShowDriverSelector(false);
  }

  async function onComplete() {
    try {
      setLoading(true);
      const response = await scannerAPI('scan/sort/close', {
        method: "POST",
        body: {
          scannedOrders,
          rack,
          missingOrderIds,
          driver: selectedDriver,
        }
      });
      message.success(response.message);
      onSubmit();
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalStyles.Container>
      <ModalStyles.MainText>
        Closing - {rack.name}
      </ModalStyles.MainText>
      <WarehouseUI.DisplayBox>
        <WarehouseUI.DisplayLabel>{`${scannedOrders.length} / ${totalOrderIds} scanned`}</WarehouseUI.DisplayLabel>
      </WarehouseUI.DisplayBox>

      <ModalStyles.StyledDivider />
      <ModalStyles.MainText>{`${missingOrderIds.length} missing scans`}</ModalStyles.MainText>
      {
        missingOrderIds.map((id: string) => (
          <ModalStyles.LabelText>
            {`${rack.name} - ${getSequence(id)}`}
          </ModalStyles.LabelText>
        ))
      }
      <ModalStyles.StyledDivider />
      {
        !loading && (
          <>
            <ModalStyles.MainText>Assign to Driver & Close sort</ModalStyles.MainText>
            {
              selectedDriver ? (
                <WarehouseUI.DisplayBox>
                  <WarehouseUI.DisplayLabel onClick={() => setShowDriverSelector(true)}>{selectedDriver.name}</WarehouseUI.DisplayLabel>
                </WarehouseUI.DisplayBox>
              ) : (
                <WarehouseUI.DisplayBox>
                  <WarehouseUI.StyledButton onClick={() => setShowDriverSelector(true)}>
                    Select Driver
                  </WarehouseUI.StyledButton>
                </WarehouseUI.DisplayBox>
              )
            }
          </>
        )
      }

      <ModalStyles.StyledDivider />
      
      <ModalStyles.Footer>
        <ModalStyles.SecondaryButton onClick={onClose}>
          Close
        </ModalStyles.SecondaryButton>
        {
          selectedDriver && (
            <ModalStyles.PrimaryButton onClick={onComplete}>
              Assign & Close
            </ModalStyles.PrimaryButton>
          )
        }
      </ModalStyles.Footer>
      <DriverSelector
        showSelector={showDriverSelector}
        onClose={handleCloseDriverSelector}
        onSelect={handleDriverSelect}
      />
    </ModalStyles.Container>
  )
};
